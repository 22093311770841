<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk3Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk3',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-电煤招标详情'

    getBulk3Details({id: this.$route.params.id}).then(res => {
      this.tableData = [
        {text: '项目名称', value: res[0]?.title},
        {text: '装货港', value: res[0]?.loadPort + '-' + res[0]?.unloadPort},
        {text: '货种', value: res[0]?.goodsType},
        {text: '数量', value: res[0]?.numbers},
        {text: '受载期时间', value: res[0]?.loadStartDate + '-' + res[0]?.loadEndDate},
        {text: '装卸天数', value: res[0]?.loadDay},
        {text: '滞期费', value: res[0]?.expense},
        {text: '截止时间', value: res[0]?.endDate},
        {text: '备注', value: res[0]?.remarks},
        {text: '联系人', value: res[0]?.contactPerson},
        {text: '联系电话', value: res[0]?.contactPhone},
        {text: '货方', value: res[0]?.consignor}

      ]

    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
